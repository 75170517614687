import toast from 'react-hot-toast';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ONLINE_ORDER,
    PLACE_ORDER_ENDPOINT,
} from '@/constants/endpoints/checkoutEndPoints';
import { postApi } from '@/scripts/api-services';

export const placeOrder: any = createAsyncThunk(
    'checkout/placeOrder',
    async (reqData: any) => {
        try {
            const response = await postApi(PLACE_ORDER_ENDPOINT, reqData);
            return response;
        } catch (error: any) {
            console.error('Error in placing order: ', error);
            if (error?.message?.[0]) {
                toast.error(error?.message?.[0]);
            }
            return Promise.reject(error);
        }
    }
);

export const OnlineOrder: any = createAsyncThunk(
    'checkout/OnlineOrder',
    async ({ grandTotal, orderId }: any, { rejectWithValue }) => {
        try {
            const res = await postApi(ONLINE_ORDER, {
                orderId,
                // TODO -> why is the phone number hard-coded here?
                mobileNumber: '9999999999',
                amount: grandTotal,
            });
            return { res };
        } catch (error) {
            console.log('Error fetch  API', error);
            return rejectWithValue(error);
        }
    }
);

export const updatePatientDetail: any = createAsyncThunk(
    'checkout/updatePatientDetail',
    async (payload) => {
        const { patientInfo } = payload as any;

        try {
            const response = await postApi('user/patients', patientInfo);

            if (response?.status === 201) {
                toast.success('Patient detail updated successfully');
                return response.data;
            } else {
                toast.error(response?.message[0]);
                return Promise.reject();
            }
        } catch (error: any) {
            toast.error(
                'There was an error updating the patient detail. Please retry.'
            );
            return Promise.reject(error);
        }
    }
);
