export const GET_CART_ENDPOINT = '/cart'; //get request

export const ADD_CART_ITEM_ENDPOINT = '/cart/add-item';

export const UPDATE_CART_QUANTITY_ENDPOINT = '/cart/item-quantity';

export const DELETE_CART_ITEM_ENDPOINT = '/cart/item/'; // /cart/item/:id

export const CLEAR_CART_ENDPOINT = '/cart'; //patch request

export const GET_CART_ITEM_COUNT_ENDPOINT = '/cart/item-count';

export const UPDATE_DELIVERY_TYPE = '/cart/updateDeliveryType';

export const UPDATE_STORE_IN_CART = '/cart/updateCartInventoryByStore';
