// delivery type options start
export const HOME_DELIVERY = "home-delivery"
export const STORE_PICKUP = "store-pickup"
// delivery type options end

// payment method options start
export const CASH_ON_DELIVERY = "cash-on-delivery"
export const ONLINE = "online"
export const PAY_AT_STORE = 'pay-at-store'
// payment method options end
