import {
    CREATE_NEW_WISHLIST_PRODUCT,
    GET_WISHLIST_PRODUCT,
    REMOVE_WISHLIST_PRODUCT,
} from '@/constants/endpoints/wishlistEndpoints';
import { patchApi, postApi } from '@/scripts/api-services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from 'cookies-next';
import toast from 'react-hot-toast';

/*--------------------WishList Data -------------------- */

export const getWistlistData: any = createAsyncThunk(
    'whislist/wishlist-data',
    async (reqData: any) => {
        try {
            const response = await postApi(`${GET_WISHLIST_PRODUCT}`, reqData);
            return response;
        } catch (error) {
            console.log('Error fetch wishlist API', error);
            return Promise.reject(error);
        }
    }
);

/*--------------------Create WishList -------------------- */

interface WishListCreate {
    productId?: string;
    productType?: string;
}

export const CreateWistlistData: any = createAsyncThunk(
    'whislist/create-wishlist',
    async ({ productId, productType }: WishListCreate) => {
        try {
            const token = getCookie('accessToken');
            if (!token) {
                toast.error('Please login to add product in the wishlist');
                return;
            }
            const response = await postApi(CREATE_NEW_WISHLIST_PRODUCT, {
                products: {
                    type: productType,
                    productId: productId,
                },
            });
            toast.success('Product has been added to wishlist');
            return response;
        } catch (error: any) {
            console.log('Error fetch wishlist API', error);
            toast.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*--------------------Create WishList -------------------- */

interface WishListRemove {
    productId?: string;
    productType?: string;
    errorMsg?: boolean;
}

export const RemoveWistlistData: any = createAsyncThunk(
    'whislist/remove-wishlist',
    async ({ productId, productType, errorMsg }: WishListRemove) => {
        try {
            const token = getCookie('accessToken');
            if (!token) {
                return;
            }
            const response = await patchApi(REMOVE_WISHLIST_PRODUCT, {
                products: {
                    type: productType,
                    productId: productId,
                },
            });
            if (!errorMsg) {
                toast.success('Product has been removed from wishlist');
            }
            return response;
        } catch (error: any) {
            console.log('Error fetch wishlist API', error);
            toast.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);
