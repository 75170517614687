// GoogleAnalytics.tsx

import React from 'react';
import Script from 'next/script';

import { GTag_ID } from '@/env';

const GoogleAnalytics = () => {
    return (
        <>
            <Script
                strategy="lazyOnload"
                src={`https://www.googletagmanager.com/gtag/js?id=${GTag_ID}`}
            />

            <Script id="" strategy="lazyOnload">
                {`console.log('path name is: ', window.location.pathname)`}
                {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GTag_ID}', {
              page_path: window.location.pathname,
              });
          `}
            </Script>
        </>
    );
};

export const GAEventNames = {
    AddToCart: 'add_to_cart',
    SignIn: 'sign_in',
    SignUp: 'sing_up',
    ProductSearch: 'product_search',
    Checkout: 'checkout'
}

// what does value do?
// action - event name
// inside action - category and label name come as event_category and event_label respectively
export const sendGAEvent = (arg: {[key: string]: any}) => {
    const { action, category, label, ...rest } = arg || {};
    (window as any)?.gtag?.('event', action, {
        event_category: category,
        event_label: label,
        ...rest
    });
};

export default GoogleAnalytics;
