import toast from 'react-hot-toast';
import { createSlice } from '@reduxjs/toolkit';
import { HOME_DELIVERY, STORE_PICKUP } from '@/constants/payment-constant';

import { placeOrder } from './actions/checkout-action';
import { updateDeliveryType } from './actions/cart-actions';
import { updatePatientDetail } from './actions/checkout-action';

export type Store = {
    _id: string;
    name: string;
    pincode: string;
    state: string;
    address: string;
    mobile: string;
};

export const PatientUpdateStatus = {
    NotTried: 'not-tried',
    Updating: 'updating',
    Updated: 'updated',
    Failed: 'failed',
} as const;

interface CheckoutState {
    isLoading: boolean;
    status: string | null;
    deliveryType: string | null;
    selectedBillingAddress: string;
    selectedShippingAddress: string;
    paymentMethod: string;
    prescriptionID: any[];
    isPrescriptionSelected: boolean;
    ipAddress: string;
    deviceType: string;
    deviceTypeValue: string;
    selectedPrescription: any[];
    iDontHavePrescription: boolean;
    previouslySelectedPrescriptionData: {
        selectedPrescription: any[];
        prescriptionID: any[];
        isPrescriptionSelected: boolean;
    };
    // list of stores for pickup at store
    stores: {
        default: Store | null;
        others: Store[];
        // irrelevant - it is not being used and always comes as empty array
        storeWithDifferentBatch: Store[];
        fetchedStoreData: boolean;
        fetchingStoreData: boolean;
        errorFetchingStoreData: boolean;
    };
    storesForHD: {
        default: Store | null;
        others: Store[];
        // irrelevant - it is not being used and always comes as empty array
        storeWithDifferentBatch: Store[];
        fetchedStoreData: boolean;
        fetchingStoreData: boolean;
        errorFetchingStoreData: boolean;
    };
    selectedStoreIdForPickup: string | null;
    // selectedDateTime: null | string;
    selectedHour: null | number;
    selectedMinute: null | number;
    selectedMeridian: null | 'AM' | 'PM';
    patientPrescriptionDetail: {
        updateStatus: (typeof PatientUpdateStatus)[keyof typeof PatientUpdateStatus];
        orderingForSomeoneElse: boolean;
        patientId: null | string;
    };
}

const initialState: CheckoutState = {
    isLoading: false,
    selectedBillingAddress: '',
    selectedShippingAddress: '',
    deliveryType: '',
    paymentMethod: '',
    status: '',
    prescriptionID: [],
    isPrescriptionSelected: false,
    ipAddress: '',
    deviceType: '',
    deviceTypeValue: '',
    selectedPrescription: [],
    iDontHavePrescription: false,
    previouslySelectedPrescriptionData: {
        selectedPrescription: [],
        prescriptionID: [],
        isPrescriptionSelected: false,
    },
    stores: {
        default: null,
        others: [],
        storeWithDifferentBatch: [],
        fetchedStoreData: false,
        fetchingStoreData: false,
        errorFetchingStoreData: false,
    },
    storesForHD: {
        default: null,
        others: [],
        storeWithDifferentBatch: [],
        fetchedStoreData: false,
        fetchingStoreData: false,
        errorFetchingStoreData: false,
    },
    selectedStoreIdForPickup: null,
    // selectedDateTime: null,
    selectedHour: null,
    selectedMinute: null,
    selectedMeridian: 'AM',
    patientPrescriptionDetail: {
        updateStatus: PatientUpdateStatus.NotTried,
        orderingForSomeoneElse: false,
        patientId: null,
    },
};

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setDeviceType: (state, action) => {
            state.deviceType = action.payload;
        },
        setDeviceTypeValue: (state, action) => {
            state.deviceTypeValue = action.payload;
        },
        setIPAddress: (state, action) => {
            state.ipAddress = action.payload;
        },
        setDeliveryType: (state, action) => {
            state.deliveryType = action.payload;
        },
        setBillingAddress: (state, action) => {
            state.selectedBillingAddress = action.payload;
        },
        setShippingAddress: (state, action) => {
            state.selectedShippingAddress = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        // probably this is tracking the prescription ids that will finally be sent in payload while creating order
        setSelectedPrescriptionID: (state, action) => {
            (state.prescriptionID = action.payload),
                (state.isPrescriptionSelected = true);
        },
        // this probably is the list of prescriptions that have been selected in the prescription modal
        setSelectedPrescription: (state, action) => {
            state.selectedPrescription = action.payload;
        },
        deleteSelectedPrescription: (state, action) => {
            const prescriptionIdToDelete = action.payload;
            state.selectedPrescription = state.selectedPrescription.filter(
                (item: any) => item._id !== prescriptionIdToDelete
            );
            state.prescriptionID = state.prescriptionID.filter(
                (id: string) => id !== prescriptionIdToDelete
            );
            state.isPrescriptionSelected =
                state.selectedPrescription.length === 0 ? false : true;
        },
        setRemovePrescription: (state) => {
            (state.prescriptionID = []), (state.isPrescriptionSelected = false);
        },
        resetCheckoutStore: (state) => {
            (state.prescriptionID = []),
                (state.isPrescriptionSelected = false),
                (state.paymentMethod = ''),
                (state.selectedBillingAddress = ''),
                (state.selectedShippingAddress = ''),
                (state.deliveryType = '');
            Object.assign(state, {
                selectedPrescription: [],
                iDontHavePrescription: false,
                previouslySelectedPrescriptionData:
                    initialState.previouslySelectedPrescriptionData,
            });
            state.selectedStoreIdForPickup = null;
            state.patientPrescriptionDetail.orderingForSomeoneElse =false;
            state.patientPrescriptionDetail.patientId = null;
            state.patientPrescriptionDetail.updateStatus =
                PatientUpdateStatus.NotTried;
            state.selectedHour = null;
            state.selectedMinute = null;
            state.selectedMeridian = null;
        },
        toggleHavePrescription: (state) => {
            const {
                selectedPrescription,
                prescriptionID,
                isPrescriptionSelected,
                previouslySelectedPrescriptionData,
            } = state;
            // assign the previous values to the current keys
            if (state.iDontHavePrescription) {
                Object.assign(state, previouslySelectedPrescriptionData);
            } else {
                state.previouslySelectedPrescriptionData = {
                    selectedPrescription,
                    prescriptionID,
                    isPrescriptionSelected,
                };
                state.selectedPrescription = [];
                state.prescriptionID = [];
                state.isPrescriptionSelected = false;
            }

            state.iDontHavePrescription = !state.iDontHavePrescription;
        },
        setStoreIdForPickup: (state, action) => {
            const { payload } = action;
            const { storeId } = payload;
            state.selectedStoreIdForPickup = storeId;
        },
        updateSelectedDateTime: (state, action) => {
            const { payload } = action;
            // const { dateTime } = payload;
            // state.selectedDateTime = dateTime;
            Object.assign(state, payload);
        },
        updatePatientPrescriptionDetail: (state, action) => {
            const { payload } = action;
            Object.assign(state.patientPrescriptionDetail, payload);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(placeOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'fulfilled';
        });
        builder.addCase(placeOrder.pending, (state, action) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(placeOrder.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'rejected';
        });
        builder.addCase(updateDeliveryType.pending, (state, action) => {
            const { meta } = action;
            const deliveryType = meta.arg.deliveryType;

            if (deliveryType === STORE_PICKUP) {
                state.stores.fetchingStoreData = true;
            }

            if (deliveryType === HOME_DELIVERY) {
                state.storesForHD.fetchingStoreData = true;
            }
        });
        builder.addCase(updateDeliveryType.fulfilled, (state, action) => {
            const { meta, payload } = action;
            const deliveryType = meta.arg.deliveryType;

            if (deliveryType === STORE_PICKUP && payload?.data?.data) {
                const defaultStore = payload.data.data.defaultStore;
                const others = payload.data.data?.multistore;
                const storeWithDifferentBatch =
                    payload.data.data?.storeWithDifferentBatch;

                state.stores = {
                    default: defaultStore,
                    others,
                    storeWithDifferentBatch,
                    fetchedStoreData: true,
                    fetchingStoreData: false,
                    errorFetchingStoreData: false,
                };

                if (state.selectedStoreIdForPickup) {
                    // not considering store ids with different batch because it is irrelevant now
                    const allPossibleStoreIds = [defaultStore?._id, ...others.map((store: Store) => store?._id)];
                    if (allPossibleStoreIds.includes(state.selectedStoreIdForPickup)) {

                    } else {
                        state.selectedStoreIdForPickup = null;
                    }
                }
            }

            if (deliveryType === HOME_DELIVERY && payload?.data?.data) {
                const others = payload.data.data.multistore;
                const storeWithDifferentBatch =
                    payload.data.data.storeWithDifferentBatch;

                if (
                    !payload.data.data?.defaultStore &&
                    !others.length &&
                    storeWithDifferentBatch.length
                ) {
                    toast.success('The product available for your location is from a different batch so the price may vary');
                }
                state.storesForHD = {
                    default: payload.data.data.defaultStore,
                    others,
                    storeWithDifferentBatch,
                    fetchedStoreData: true,
                    fetchingStoreData: false,
                    errorFetchingStoreData: false,
                };
            }
        });
        builder.addCase(updateDeliveryType.rejected, (state, action) => {
            const { meta } = action;
            const deliveryType = meta.arg.deliveryType;

            if (deliveryType === STORE_PICKUP) {
                state.stores.fetchingStoreData = false;
                state.stores.errorFetchingStoreData = true;

                state.stores.default = initialState.stores.default;
                state.stores.others = initialState.stores.others;
                state.stores.storeWithDifferentBatch = initialState.stores.storeWithDifferentBatch;
            }

            if (deliveryType === HOME_DELIVERY) {
                state.storesForHD.fetchingStoreData = false;
                state.storesForHD.errorFetchingStoreData = true;
            }
        });
        builder.addCase(updatePatientDetail.pending, (state, action) => {
            state.patientPrescriptionDetail.updateStatus = PatientUpdateStatus.Updating;
        });
        builder.addCase(updatePatientDetail.fulfilled, (state, action) => {
            const { payload } = action;
            state.patientPrescriptionDetail.patientId = payload.data.patientId;
            state.patientPrescriptionDetail.updateStatus = PatientUpdateStatus.Updated;
        });
        builder.addCase(updatePatientDetail.rejected, (state, action) => {
            state.patientPrescriptionDetail.updateStatus = PatientUpdateStatus.Failed;
        });
    },
});

export const {
    setShippingAddress,
    setDeviceTypeValue,
    setDeviceType,
    setIPAddress,
    resetCheckoutStore,
    setRemovePrescription,
    setBillingAddress,
    setPaymentMethod,
    setDeliveryType,
    setSelectedPrescriptionID,
    setSelectedPrescription,
    deleteSelectedPrescription,
    toggleHavePrescription,
    setStoreIdForPickup,
    updateSelectedDateTime,
    updatePatientPrescriptionDetail,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
